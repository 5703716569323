
import React, { createContext } from 'react';
import Publisher from './Publisher';
export var PublisherContext = createContext();
var Provider = PublisherContext.Provider,
  Consumer = PublisherContext.Consumer;
export { Provider, Consumer };
export var PublisherProvider = function PublisherProvider(_ref) {
  var children = _ref.children,
    events = _ref.events,
    settings = _ref.settings;
  var publisher = new Publisher({
    events: events
  });
  publisher.setSettings(settings);
  return React.createElement(Provider, {
    value: publisher
  }, children);
};