import omit from 'lodash/omit';
import styled, { createGlobalStyle } from 'styled-components';
import { getPaletteColor, getTextColor, color } from '@magalu/stereo-ui-styles';

export const Container = styled.main.attrs(props => ({
  bg: props.layout.bg,
}))`
  width: 100%;
  min-height: 100vh;

  ${props => omit(props.layout, ['bg'])};
  ${color}
`;

const getFontFace = (fonts = {}) => {
  return Object.keys(fonts).map(key => ({
    '@font-face': {
      'font-display': 'swap',
      'font-family': `'${fonts[key].fontFamily}'`,
      'font-style': 'normal',
      'font-weight': `${fonts[key].fontWeight}`,
      src: fonts[key].src,
    },
  }));
};

export const StyleResets = createGlobalStyle`

  ${props => getFontFace(props.theme?.fonts?.variations)}

  html, body {
    margin: unset;
    background-color: ${props => getPaletteColor('background.base')(props)};
    color: ${props => getTextColor('base')(props)};
    font-family: ${props => props.theme?.fonts?.name};
  }
  ol, ul {
    list-style: none;
  }
  *, *:before, *:after {
    box-sizing: border-box;
    text-decoration: none;
  }
  body {
    position: relative;
  }
  a:hover{
    border:0px solid transparent;
  }
  a:focus {
    border: 0px solid transparent;
  }
  a:focus-visible{
    outline: auto 1px Highlight;
    outline: auto 1px -webkit-focus-ring-color;
  }
`;
