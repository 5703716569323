import React from 'react';
import { shape, string } from 'prop-types';

import Target from '../Target';

function Slice(props) {
  const { slice, ...targetProps } = props;

  const renderChildren = sliceChild => {
    if (!sliceChild) return null;
    if (sliceChild.children) {
      return renderChildren(sliceChild.children);
    }
    if (Array.isArray(sliceChild)) {
      return sliceChild.map(c => {
        return (
          <Target key={c.id} slice={c} {...targetProps}>
            {renderChildren(c?.children)}
          </Target>
        );
      });
    }
    return <Target key={sliceChild.id} slice={sliceChild} {...targetProps} />;
  };

  if (slice.type === 'group') {
    return (
      <section style={{ gridArea: `${slice.grid_id}` }}>
        {slice.components?.map(child => (
          <Target key={child.id} {...targetProps} slice={child}>
            {renderChildren(child)}
          </Target>
        ))}
      </section>
    );
  }

  return (
    <Target {...props}>
      {slice.children?.map(child => (
        <Target key={child.id} slice={child} {...targetProps} />
      ))}
    </Target>
  );
}

Slice.defaultProps = {
  config: {},
  data: {},
  structure: {},
};

Slice.propTypes = {
  config: shape({}),
  data: shape({}),
  slice: shape({
    name: string,
  }).isRequired,
  structure: shape({}),
};

export default Slice;
