import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { string, bool, shape } from 'prop-types';

// Mixer imports
import { routePush } from '@magalu/mixer-utils';
import { parsePlaceholders } from '@magalu/mixer-structure';

const routeRedirect = (
  condition,
  { asPath = '/', fallBack, spa = true, url },
  dataSource
) => {
  const conditions = {
    default: routePush({
      path: asPath,
      spa,
    }),
    [false]: routePush({
      path: parsePlaceholders(fallBack || asPath, dataSource),
      spa,
    }),
    [true]: routePush({
      path: parsePlaceholders(url, dataSource),
      spa,
    }),
  };

  return conditions[condition] || conditions.default;
};

/**
 * Redirect component used to navigate to a new location.
 * based on: https://v5.reactrouter.com/web/api/Redirect
 * based on: https://www.npmjs.com/package/next-redirects
 *
 * @param {boolean} condition
 * @param {string} fallBack
 * @param {boolean} spa
 * @param {string} url
 * @returns null
 */
function Redirect({ condition, fallBack, spa, config, url }) {
  const { asPath } = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      routeRedirect(
        condition ?? true,
        { asPath, fallBack, spa, url },
        { config, window }
      );
    }
  }, [condition]);

  return null;
}

Redirect.propTypes = {
  condition: bool,
  config: shape({}),
  fallBack: string,
  spa: bool,
  url: string.isRequired,
};

export default Redirect;
