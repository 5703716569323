import React from 'react';
import { node, number, shape, string } from 'prop-types';

import LazyLoadComponent from '@magalu/stereo-ui/atoms/LazyLoadComponent';

const InScreen = ({ children, layout, options }) => (
  <LazyLoadComponent layout={layout} options={options}>
    {children}
  </LazyLoadComponent>
);

InScreen.propTypes = {
  children: node.isRequired,
  layout: shape({}),
  options: shape({
    rootMargin: string,
    threshold: number,
  }),
};

InScreen.defaultProps = {
  layout: null,
  options: {
    rootMargin: '0px',
    threshold: 0.1,
  },
};

InScreen.ssr = false;
InScreen.displayName = 'InScreen';

export default InScreen;
