import React, { useState } from 'react';
import { node, shape } from 'prop-types';
import { useRouter } from 'next/router';
import get from 'lodash/get';
import getConfig from 'next/config';
import { parsePlaceholders } from '@magalu/mixer-structure';
import {
  whereAmI,
  useCookies,
  Logger,
  useCloseMiniApp,
  useCheckUserCredentials,
  useRequestUserCredential,
} from '@magalu/mixer-utils';

// Redirect component
import Redirect from '../components/Redirect';

const verifyLoginFromKey = (structure, data) => {
  const privateRouteCheck = parsePlaceholders(structure.privateRouteCheck, {
    ...structure,
    ...data,
  });
  if (privateRouteCheck) return false;
  return true;
};

const isAPrivateRoute = ({ config, private: isPrivate }) =>
  isPrivate && !!config?.loggedUser && !!config?.loginUrl;

const userIsLogged = structure => {
  const { config } = structure;
  return !!get(structure, config?.loggedUser, false);
};

const withPrivateRoute = Page => {
  const WrapComponent = props => {
    const { structure: initialStructure, data } = props;
    const { back } = useRouter();
    const config = getConfig();
    const { publicRuntimeConfig } = config;

    const cookies = whereAmI.onClient
      ? useCookies({}, { config })
      : initialStructure?.cookies;
    const structure = { ...initialStructure, cookies };

    const [validateAppLogin, setValidateAppLogin] = useState(
      !!structure?.private && !!structure?.config?.isMiniApp
    );

    const checkMiniAppLogin = async () => {
      const logger = Logger(structure?.config);
      try {
        const logged = await useCheckUserCredentials(logger);
        if (!logged) await useRequestUserCredential(logger);

        setValidateAppLogin(false);
      } catch (error) {
        return window?.history?.length > 1 ? back() : useCloseMiniApp(logger);
      }
      return null;
    };

    const shouldRedirect =
      whereAmI.onClient &&
      verifyLoginFromKey(structure, data) &&
      isAPrivateRoute(structure) &&
      !userIsLogged(structure);

    if (whereAmI.onClient && validateAppLogin) {
      checkMiniAppLogin();
      return null;
    }

    if (shouldRedirect) {
      return (
        <Redirect
          config={publicRuntimeConfig}
          url={structure?.config?.loginUrl}
          spa={false}
        />
      );
    }

    return <Page {...props} config={config} cookies={cookies} structure={structure} />;
  };

  WrapComponent.defaultProps = {
    data: {},
    structure: {},
  };

  WrapComponent.propTypes = {
    data: shape({}),
    structure: shape({}),
  };

  return WrapComponent;
};

withPrivateRoute.propTypes = {
  page: node,
};

export default withPrivateRoute;
