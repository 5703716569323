import dynamic from 'next/dynamic';
import { string, arrayOf } from 'prop-types';
import { inventory } from '@magalu/mixer-events';
import { Subscriber } from '@magalu/mixer-publisher';

const eventsInventory = inventory.reduce(
  (acc, eventType) => ({
    ...acc,
    [eventType]: dynamic(() =>
      import(`@magalu/mixer-events/build/esm/${eventType}`).then(
        ({ default: events }) => {
          return function EventSubscriberComponent() {
            return <Subscriber events={events} />;
          };
        }
      )
    ),
  }),
  {}
);

function EventSubscriber({ types }) {
  return (
    <>
      {types
        .filter(type => !!eventsInventory[type])
        .map(type => {
          const Module = eventsInventory[type];
          return <Module key={`event-subscriber-${type}`} />;
        })}
    </>
  );
}

EventSubscriber.defaultProps = {
  types: [],
};

EventSubscriber.propTypes = {
  types: arrayOf(string),
};

export default EventSubscriber;
