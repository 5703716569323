import { useEffect, Fragment } from 'react';
import Head from 'next/head';
import { shape } from 'prop-types';
import { usePublisher } from '@magalu/mixer-publisher';
import { parseFiltersMetaTags, getFullTitle } from '@magalu/mixer-utils';
import Slice from '../Slice';
import Meta from '../Meta';
import { Container, StyleResets } from './globalStyles';

const MainContainer = ({ structure, data, config, modules }) => {
  const { components, head, metaTags, layout = {}, ...pageInfo } = structure || {};
  const { publish } = usePublisher();

  useEffect(() => {
    publish('pageview');
  }, [JSON.stringify(structure)]);

  const hasLayout = Object.entries(layout).length;
  const Wrapper = hasLayout ? Container : Fragment;
  const wrapperProps = hasLayout ? { layout } : {};

  const getQueryString = url => (url?.includes('?') ? `?${url.split('?').pop()}` : '');

  const renderSlice = slice => (
    <Slice
      key={slice.id || slice.grid_id}
      slice={slice}
      data={data}
      structure={pageInfo}
      config={config}
      utils={{ getFullTitle }}
      modules={modules}
    />
  );
  return (
    <>
      <Head>{head?.map(renderSlice)}</Head>
      <Wrapper {...wrapperProps}>
        <Meta
          tags={metaTags}
          data={{
            ...data,
            ...structure,
            meta: {
              alternate: structure?.config?.alternateDomain,
              canonical: structure?.config?.canonicalDomain,
              filters: {
                brand: parseFiltersMetaTags(data?.search?.filters, 'brand'),
              },
              ogImage: data?.product?.image
                ?.replace('{w}', structure?.config?.images?.width)
                ?.replace('{h}', structure?.config?.images?.height),
              parameter: getQueryString(structure?.canonicalPath?.toLowerCase()),
              path: structure?.canonicalPath?.slice(1).toLowerCase(),
            },
            utils: { getFullTitle },
          }}
        />
        <StyleResets />
        {components?.map(renderSlice)}
      </Wrapper>
    </>
  );
};

MainContainer.defaultProps = {
  config: {},
  modules: {},
};

MainContainer.propTypes = {
  config: shape({}),
  data: shape({}).isRequired,
  modules: shape({}),
  structure: shape({}).isRequired,
};

export default MainContainer;
