import { bool, shape, string, oneOfType, arrayOf, element, node } from 'prop-types';
import { parsePlaceholders } from '@magalu/mixer-structure';
import InScreen from '../InScreen';
import shouldRender from '../../utils/shouldRender';

const withLazy = (lazy, children) => {
  return lazy ? (
    <InScreen options={lazy?.options} layout={lazy?.layout}>
      {children}
    </InScreen>
  ) : (
    children
  );
};

function Target({ children, modules, ...props }) {
  const { slice, data, structure, config, utils, ...moduleProps } = props;
  const Module = modules[slice?.name];
  return Module && shouldRender(props) ? (
    withLazy(
      !structure?.isGooglebot && slice?.lazy,
      <Module
        id={slice.id}
        data={data}
        layout={slice?.layout}
        static={parsePlaceholders(slice?.static, { ...structure, ...data, utils })}
        structure={structure}
        config={config}
        {...moduleProps}
      >
        {children}
      </Module>
    )
  ) : (
    <></>
  );
}

Target.defaultProps = {
  children: null,
  config: {},
  data: {},
  modules: {},
  structure: {},
  utils: {},
};

Target.propTypes = {
  children: oneOfType([element, string, arrayOf(element), node]),
  config: shape({}),
  data: shape({}),
  modules: shape({}),
  slice: shape({
    lazy: oneOfType([bool, shape({})]),
    name: string.isRequired,
  }).isRequired,
  structure: shape({}),
  utils: shape({}),
};

export default Target;
