import React from 'react';
import Head from 'next/head';
import { arrayOf, shape } from 'prop-types';
import { parsePlaceholders } from '@magalu/mixer-structure';

const Meta = ({ tags, data }) => {
  const getKey = (key, dataSource) => {
    if (!key) return null;
    if (typeof key === 'string') return parsePlaceholders(key, dataSource);
    const { value } = parsePlaceholders(key, dataSource);
    return value?.toString();
  };
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0 maximum-scale=1.0"
      />

      {tags?.map((item, idx) => {
        const parsedItem = Object.keys(item).reduce(
          (result, key) => ({
            ...result,
            [key]: getKey(item[key], data),
          }),
          {}
        );

        const { type, children, ...other } = parsedItem;
        const props = { 'data-testid': `link-${idx}`, key: idx, ...other };
        return React.createElement(type, props, children);
      })}
    </Head>
  );
};

Meta.defaultProps = {
  data: {},
  tags: [],
};

Meta.propTypes = {
  data: shape({}),
  tags: arrayOf(shape({})),
};

export default Meta;
