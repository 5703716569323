import { shape } from 'prop-types';
import usePubliser from './usePublisher';
var Subscriber = function Subscriber(_ref) {
  var events = _ref.events;
  var _usePubliser = usePubliser(),
    subscribeAll = _usePubliser.subscribeAll;
  subscribeAll(events);
  return null;
};
Subscriber.propTypes = {
  events: shape({}).isRequired
};
export default Subscriber;